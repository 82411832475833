













import Vue from "vue";
import { mapActions } from "vuex";
import BhSection from "@/components/BhSection.vue";

export default Vue.extend({
  name: "AlgorithmHome",
  metaInfo: {
    title: "Algorithm",
  },
  components: { BhSection },
  methods: {
    ...mapActions(["resetOptions", "initOptions"]),
  },
  created() {
    this.resetOptions();
    this.initOptions();
    const oid = "q1";
    this.$router.push({ name: "Questions", params: { oid } });
  },
});
