import Vue from "vue";
import Vuex from "vuex";
import {
  AlgorithmOption,
  AlgorithmOptions,
  ResultPath,
  Selection,
} from "@/models/Types";
import Questions from "@/store/Questions";
import Answers from "@/store/Answers";

Vue.use(Vuex);

const Options: AlgorithmOptions = { ...Questions, ...Answers };

interface AppState {
  previousOptions: ResultPath[];
  activeOption: AlgorithmOption | null;
  options: AlgorithmOptions;
}

const state: AppState = {
  previousOptions: [],
  activeOption: null,
  options: Options,
};

export enum Mutations {
  SET_ACTIVE_OPTION = "SET_ACTIVE_OPTION",
  APPEND_PREVIOUS_OPTION = "APPEND_PREVIOUS_OPTION",
  POP_PREVIOUS_OPTION = "POP_PREVIOUS_OPTION",
  RESET_OPTIONS = "RESET_OPTIONS",
}
export default new Vuex.Store({
  state,
  getters: {
    previousOption: (state) => {
      if (state.previousOptions.length) {
        return state.previousOptions[state.previousOptions.length - 1].option;
      }
      return null;
    },
    thisOption: (state) => state.activeOption,
    allOptions: (state) => state.options,
    getResults: (state) => state.previousOptions,
  },
  mutations: {
    [Mutations.SET_ACTIVE_OPTION](state: AppState, payload: AlgorithmOption) {
      state.activeOption = payload;
    },
    [Mutations.APPEND_PREVIOUS_OPTION](
      state: AppState,
      payload: { option: AlgorithmOption; selection: Selection }
    ) {
      if (payload) {
        state.previousOptions.push({
          option: payload.option,
          selection: payload.selection,
        });
      }
    },
    [Mutations.POP_PREVIOUS_OPTION](state: AppState) {
      if (state.previousOptions.length) state.previousOptions.pop();
    },
    [Mutations.RESET_OPTIONS](state: AppState) {
      state.activeOption = null;
      state.previousOptions = [];
    },
  },
  actions: {
    initOptions({ commit, state }) {
      commit(Mutations.SET_ACTIVE_OPTION, state.options["q1"]);
      return state.activeOption;
    },
    gotToNextOption(
      { commit, state },
      payload: { optionId: string; selection: Selection }
    ) {
      commit(Mutations.APPEND_PREVIOUS_OPTION, {
        option: state.activeOption,
        selection: payload.selection,
      });
      commit(Mutations.SET_ACTIVE_OPTION, state.options[payload.optionId]);
      return state.activeOption;
    },
    goToPreviousOption({ commit, state, getters }) {
      if (!getters.previousOption) return null;

      commit(Mutations.SET_ACTIVE_OPTION, getters.previousOption);
      commit(Mutations.POP_PREVIOUS_OPTION);
      return state.activeOption;
    },
    resetOptions({ commit }) {
      commit(Mutations.RESET_OPTIONS);
    },
  },
  modules: {},
});
