





































































































import Vue from "vue";
import BhSection from "@/components/BhSection.vue";

export default Vue.extend({
  name: "TheFooterContent",
  components: { BhSection },
});
