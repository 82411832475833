import { Edges } from "@/models/Edges";
import { Component } from "vue";

export enum Selection {
  Yes,
  No,
}

export enum Response {
  Question,
  Answer,
}

export interface Question {
  id: string;
  edges: Edges;
  content: Component;
  furtherInfo?: Component;
  type: Response;
  buttons?: string[];
}

export interface Answer {
  id: string;
  content: Component;
  furtherInfo: Component;
  type: Response;
  hasTreatment: boolean;
}

export interface Answers {
  [key: string]: Answer;
}

export interface Questions {
  [key: string]: Question;
}

export type AlgorithmOption = Question | Answer;

export interface AlgorithmOptions {
  [key: string]: AlgorithmOption;
}

export interface ResultPath {
  option: AlgorithmOption;
  selection: Selection;
}
