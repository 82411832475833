




















































































import Vue from "vue";
import BhSection from "@/components/BhSection.vue";
import TheHomeIntroText from "@/content/home/TheHomeIntroText.vue";

export default Vue.extend({
  name: "Home",
  metaInfo: {
    title: "Bone Health Treatment Algorithm",
    titleTemplate: "",
  },
  components: { TheHomeIntroText, BhSection },
});
