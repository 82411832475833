

































import TheNavBar from "@/components/navigation/TheNavBar.vue";
import Vue from "vue";
import BhSection from "@/components/BhSection.vue";

export default Vue.extend({
  name: "TheAppHeader",
  components: { BhSection, TheNavBar },
  data: (): { desktop: boolean; viewWidth: number } => ({
    desktop: window.innerWidth >= 1280,
    viewWidth: window.innerWidth,
  }),
  computed: {
    logoWidth(): number {
      if (this.viewWidth < 400) {
        return 150;
      }
      return 250;
    },
  },
  created(): void {
    window.onresize = () => {
      this.viewWidth = window.innerWidth;
      this.desktop = this.viewWidth >= 1280;
    };
  },
});
