import { Question, Questions, Response } from "@/models/Types";
import Q1 from "@/content/questions/1/Q1.vue";
import Q2 from "@/content/questions/2/Q2.vue";
import Q3 from "@/content/questions/3/Q3.vue";
import Q3Info from "@/content/questions/3/Q3Info.vue";
import Q4 from "@/content/questions/4/Q4.vue";
import Q5 from "@/content/questions/5/Q5.vue";
import Q5Info from "@/content/questions/5/Q5Info.vue";
import Q6 from "@/content/questions/6/Q6.vue";
import Q7 from "@/content/questions/7/Q7.vue";
import Q8 from "@/content/questions/8/Q8.vue";
import Q8Info from "@/content/questions/8/Q8Info.vue";
import Q9 from "@/content/questions/9/Q9.vue";
import Q10 from "@/content/questions/10/Q10.vue";
import Q10info from "@/content/questions/10/Q10info.vue";
import Q11 from "@/content/questions/11/Q11.vue";
import Q12 from "@/content/questions/12/Q12.vue";
import Q12Info from "@/content/questions/12/Q12Info.vue";
import Q13 from "@/content/questions/13/Q13.vue";
import Q13Info from "@/content/questions/13/Q13Info.vue";
import Q14 from "@/content/questions/14/Q14.vue";
import Q14Info from "@/content/questions/14/Q14Info.vue";
import Q15 from "@/content/questions/15/Q15.vue";
import Q15Info from "@/content/questions/15/Q15Info.vue";

const Question1: Question = {
  id: "q1",
  content: Q1,
  edges: { 0: "q2", 1: "q14" },
  type: Response.Question,
};

const Question2: Question = {
  id: "q2",
  content: Q2,
  edges: { 0: "q3", 1: "q7" },
  type: Response.Question,
  buttons: ["Male", "Female"],
};

const Question3: Question = {
  id: "q3",
  edges: { 0: "q4", 1: "q5" },
  content: Q3,
  furtherInfo: Q3Info,
  type: Response.Question,
};

//   { Source: 4, Destination: 6, Choice: 0 },
//   { Source: 4, Destination: A1, Choice: 1 },
const Question4: Question = {
  id: "q4",
  edges: { 0: "q6", 1: "a1" },
  content: Q4,
  type: Response.Question,
};

//   { Source: 5, Destination: A2, Choice: 0 },
//   { Source: 5, Destination: A3, Choice: 1 },
const Question5: Question = {
  id: "q5",
  edges: { 0: "a2", 1: "a3" },
  content: Q5,
  furtherInfo: Q5Info,
  type: Response.Question,
};

//   { Source: 6, Destination: A4, Choice: 0 },
//   { Source: 6, Destination: A5, Choice: 1 },
const Question6: Question = {
  id: "q6",
  edges: { 0: "a4", 1: "a5" },
  content: Q6,
  type: Response.Question,
};

//   { Source: 7, Destination: 8, Choice: 0 },
//   { Source: 7, Destination: 12, Choice: 1 },
const Question7: Question = {
  id: "q7",
  edges: { 0: "q8", 1: "q12" },
  content: Q7,
  type: Response.Question,
};

//   { Source: 8, Destination: 9, Choice: 0 },
//   { Source: 8, Destination: 10, Choice: 1 },
const Question8: Question = {
  id: "q8",
  edges: { 0: "q9", 1: "q10" },
  content: Q8,
  furtherInfo: Q8Info,
  type: Response.Question,
};

//   { Source: 9, Destination: 11, Choice: 0 },
//   { Source: 9, Destination: A6, Choice: 1 },
const Question9: Question = {
  id: "q9",
  edges: { 0: "q11", 1: "a6" },
  content: Q9,
  type: Response.Question,
};

//   { Source: 10, Destination: A7, Choice: 0 },
//   { Source: 10, Destination: A8, Choice: 1 },
const Question10: Question = {
  id: "q10",
  edges: { 0: "a7", 1: "a8" },
  content: Q10,
  furtherInfo: Q10info,
  type: Response.Question,
};

//   { Source: 11, Destination: A9, Choice: 0 },
//   { Source: 11, Destination: A10, Choice: 1 },
const Question11: Question = {
  id: "q11",
  edges: { 0: "a9", 1: "a10" },
  content: Q11,
  type: Response.Question,
};

//   { Source: 12, Destination: A11, Choice: 0 },
//   { Source: 12, Destination: 13, Choice: 1 },
const Question12: Question = {
  id: "q12",
  edges: { 0: "a11", 1: "q13" },
  content: Q12,
  furtherInfo: Q12Info,
  type: Response.Question,
};

//   { Source: 13, Destination: A12, Choice: 0 },
//   { Source: 13, Destination: A13, Choice: 1 },
const Question13: Question = {
  id: "q13",
  edges: { 0: "a12", 1: "a13" },
  content: Q13,
  furtherInfo: Q13Info,
  type: Response.Question,
};

//   { Source: 14, Destination: A14, Choice: 0 },
//   { Source: 14, Destination: 15, Choice: 1 },
const Question14: Question = {
  id: "q14",
  edges: { 0: "a14", 1: "q15" },
  content: Q14,
  furtherInfo: Q14Info,
  type: Response.Question,
};

//   { Source: 15, Destination: A15, Choice: 0 },
//   { Source: 15, Destination: A16, Choice: 1 },
const Question15: Question = {
  id: "q15",
  edges: { 0: "a15", 1: "a16" },
  content: Q15,
  furtherInfo: Q15Info,
  type: Response.Question,
};

const Questions: Questions = {
  q1: Question1,
  q2: Question2,
  q3: Question3,
  q4: Question4,
  q5: Question5,
  q6: Question6,
  q7: Question7,
  q8: Question8,
  q9: Question9,
  q10: Question10,
  q11: Question11,
  q12: Question12,
  q13: Question13,
  q14: Question14,
  q15: Question15,
};

export default Questions;
