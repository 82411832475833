













































import Vue from "vue";

export default Vue.extend({
  name: "TheNavigationDrawerMenu",
  data: (): { group: number | null } => ({
    group: null,
  }),
  watch: {
    group(): void {
      this.$emit("update:drawer", false);
    },
  },
});
