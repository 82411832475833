


























import Vue from "vue";
import { differenceInDays } from "date-fns";

export default Vue.extend({
  name: "TheHcpNotice",
  data: () => ({ dialog: false }),
  created() {
    const acceptedHcpString = window.localStorage.getItem("hcpAcknowledged");
    if (acceptedHcpString) {
      const hcpAcknowledged = new Date(JSON.parse(acceptedHcpString));
      const daysSinceLastAccepted = differenceInDays(
        hcpAcknowledged,
        Date.now()
      );
      this.dialog = daysSinceLastAccepted > 30;
    } else {
      this.dialog = true;
    }
  },
  methods: {
    onClick(): void {
      this.dialog = false;
      window.localStorage.setItem(
        "hcpAcknowledged",
        JSON.stringify(new Date())
      );
    },
  },
});
