




















import Vue from "vue";
import TheAppHeader from "@/components/layout/TheAppHeader.vue";
import TheNavigationDrawerMenu from "@/components/navigation/TheNavigationDrawerMenu.vue";
import TheFooterContent from "@/content/footer/TheFooterContent.vue";
import TheHcpNotice from "@/components/notices/TheHcpNotice.vue";
import TheCookieNotice from "@/components/notices/TheCookieNotice.vue";
import TheBottomNavigation from "@/components/navigation/TheBottomNavigation.vue";

const scripts = [
  {
    src: "https://plausible.io/js/plausible.hash.js",
    defer: true,
    "data-domain": "bonehealth.rxdetail.io",
  },
];

export default Vue.extend({
  name: "App",
  metaInfo: {
    titleTemplate: "%s | Bone Health Treatment Algorithm",
    script: process.env.NODE_ENV === "production" ? scripts : [],
  },
  components: {
    TheBottomNavigation,
    TheCookieNotice,
    TheHcpNotice,
    TheFooterContent,
    TheNavigationDrawerMenu,
    TheAppHeader,
  },
  data: (): { drawer: boolean } => ({
    drawer: false,
  }),
});
