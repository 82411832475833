import { render, staticRenderFns } from "./A15Info.vue?vue&type=template&id=dfd81e2c&"
import script from "./A15Info.vue?vue&type=script&lang=ts&"
export * from "./A15Info.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports